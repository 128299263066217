<template>
  <div class="rules" style="display: flex;justify-content: center;width: 100%;text-align: left;">
    <div style="width: 95%">
      <h1>知识产权规则</h1>
      <p class="sub">规则发布时间：2022年01月18日</p>
      <hr>
      <p>vllshop平台 擅自发布、销售信息侵犯知识产权或侵犯用户侵犯知识产权的权利。</p>
      <p>若卖方发布本平台商品的知识产权信息、知识产权或转让有侵权行为，则可能有任何一方买家对店铺的信息、商品（包含下属的知识产权）提出侵权指控）信息、产品组查，若有正当的民间说法，则信息、商品会被人抽或抹掉。</p>
      <p class="up">语言类型及定义</p>
      <br>
      <p class="up">商标翻译</p>
      <p>未经许可的商标权人许可，在相同或类似的商品上使用与注册商标相同或近似的商标或其他涉及、妨碍商标权人使用注册商标、损害商标权人的合法权益行为。</p>
      <p class="up">著作权翻译</p>
      <p>未经权利人许可，作品擅自使用著作权，但不包括人的文字、视频、、音乐和软件图片等，构成著作权的表达。</p>
      <p>意思表示（但不包括）：</p>
      <p>1)盗版实体产品包装</p>
      <p>2) 购买商品的商品包装非盗版，但包括未经授权的受版权保护</p>
      <p>信息表示信息（包括但不限于）：</p>
      <p>产品及其包装等不受文字影响，但未经授权在店铺使用图片、文字作品保护作品</p>
      <p class="up">专利民间</p>
      <p>方便人使用、方便自行发布、销售他人（外观设计专利、实用专利或发明专利等的许可，以及其他许可人允许使用、方便使用）的专利行为。</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntellectualPropertyRule'
}
</script>

<style scoped>

</style>
